<template>
  <div style="max-width:100%; width: 100%;height:100%;overflow-x: auto;" class="d-flex flex-column py-3">
    <LeftTopPanelTitle
      :loading="loading"
      text="My Account"
      icon="user"
    />
    <ul class="nav nav-tabs d-flex justify-content-around my-3 font-weight-bold">
      <li
        v-for="(page, i) in pages"
        :key="i"
        class="nav-item text-center text-nowrap flex-grow-1"
        :class="currentPage === page.pageName ? 'active' : 'hv'"
      >
        <a
          :title="page.pageName"
          class="nav-link primary-colour"
          :class="currentPage === page.pageName ? 'active' : 'white-text font-weight-light'"
          style="border:solid 1px #333;"
          @click="currentPage = page.pageName"
        >
          <mdb-icon :icon="page.icon" style="margin-right:5px;" />
          <span
            :class="{'d-none' : currentPage != page.pageName}"
            class="d-sm-inline mr-2"
          >{{ page.pageName }}</span>
        </a>
      </li>
    </ul>
    <div v-show="!loading" class="flex-grow-1 myAccountSubsection">
      <MyDetails
        v-if="currentPage === 'My Details'"
        class="myAccountSubsectionComponent"
        @loading="setLoading($event)"
      />
      <MyPassword
        v-if="currentPage === 'My Password'"
        class="myAccountSubsectionComponent"
        @loading="setLoading($event)"
      />
      <CloseAccount
        v-if="currentPage === 'Close Account'"
        class="myAccountSubsectionComponent"
        @loading="setLoading($event)"
      />
      <Credits
        v-if="currentPage === 'Credits'"
        class="creditsSubsectionComponent"
        @loading="setLoading($event)"
      />
    </div>
  </div>
</template>

<script>
import LeftTopPanelTitle from '@/components/leftTopPanelTitle.vue'
import MyDetails from './myDetails.vue'
import CloseAccount from './closeAccount.vue'
import MyPassword from './myPassword.vue'
import Credits from './credits.vue'

export default {
  name: 'MyClub',
  components: {
    LeftTopPanelTitle, MyDetails, CloseAccount, MyPassword, Credits
  },
  data () {
    return {
      currentPage: 'My Details',
      pages: [
        { pageName: 'My Details', icon: 'edit' },
        { pageName: 'My Password', icon: 'lock' },
        { pageName: 'Close Account', icon: 'times-circle' },
        { pageName: 'Credits', icon: 'coins' }
      ],
      loading: false
    }
  },
  methods: {
    setLoading (boolean) {
      this.loading = boolean
    }
  }
}
</script>

<style scoped>
.hv a:hover {
  color:  var(--pr-color) !important;
}
</style>
<style>
.myAccountSubsection {
  overflow: auto;
  width: 100%;
}
.myAccountSubsectionComponent {
  width: 100%;
}
</style>
